exports.components = {
  "component---src-pages-blog-5-motive-pentru-care-afacarea-software-personalizat-js": () => import("./../../../src/pages/blog/5-motive-pentru-care-afacarea-software-personalizat.js" /* webpackChunkName: "component---src-pages-blog-5-motive-pentru-care-afacarea-software-personalizat-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-provocari-transformare-digitala-js": () => import("./../../../src/pages/blog/provocari-transformare-digitala.js" /* webpackChunkName: "component---src-pages-blog-provocari-transformare-digitala-js" */),
  "component---src-pages-blog-strategie-transformare-digitala-js": () => import("./../../../src/pages/blog/strategie-transformare-digitala.js" /* webpackChunkName: "component---src-pages-blog-strategie-transformare-digitala-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-science-ml-company-js": () => import("./../../../src/pages/data-science-ml-company.js" /* webpackChunkName: "component---src-pages-data-science-ml-company-js" */),
  "component---src-pages-despre-noi-js": () => import("./../../../src/pages/despre-noi.js" /* webpackChunkName: "component---src-pages-despre-noi-js" */),
  "component---src-pages-echipa-js": () => import("./../../../src/pages/echipa.js" /* webpackChunkName: "component---src-pages-echipa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intrebari-frecvente-js": () => import("./../../../src/pages/intrebari-frecvente.js" /* webpackChunkName: "component---src-pages-intrebari-frecvente-js" */),
  "component---src-pages-locatii-js": () => import("./../../../src/pages/locatii.js" /* webpackChunkName: "component---src-pages-locatii-js" */),
  "component---src-pages-politica-de-confidentialitate-js": () => import("./../../../src/pages/politica-de-confidentialitate.js" /* webpackChunkName: "component---src-pages-politica-de-confidentialitate-js" */),
  "component---src-pages-servicii-dezvoltare-migrare-cloud-js": () => import("./../../../src/pages/servicii/dezvoltare-migrare-cloud.js" /* webpackChunkName: "component---src-pages-servicii-dezvoltare-migrare-cloud-js" */),
  "component---src-pages-servicii-js": () => import("./../../../src/pages/servicii.js" /* webpackChunkName: "component---src-pages-servicii-js" */),
  "component---src-pages-servicii-management-proiect-js": () => import("./../../../src/pages/servicii/management-proiect.js" /* webpackChunkName: "component---src-pages-servicii-management-proiect-js" */),
  "component---src-pages-servicii-mentenanta-aplicatii-js": () => import("./../../../src/pages/servicii/mentenanta-aplicatii.js" /* webpackChunkName: "component---src-pages-servicii-mentenanta-aplicatii-js" */),
  "component---src-pages-servicii-securitate-cibernetica-js": () => import("./../../../src/pages/servicii/securitate-cibernetica.js" /* webpackChunkName: "component---src-pages-servicii-securitate-cibernetica-js" */),
  "component---src-pages-servicii-software-la-comanda-js": () => import("./../../../src/pages/servicii/software-la-comanda.js" /* webpackChunkName: "component---src-pages-servicii-software-la-comanda-js" */),
  "component---src-pages-servicii-testare-asigurarea-calitatii-js": () => import("./../../../src/pages/servicii/testare-asigurarea-calitatii.js" /* webpackChunkName: "component---src-pages-servicii-testare-asigurarea-calitatii-js" */),
  "component---src-pages-servicii-training-it-js": () => import("./../../../src/pages/servicii/training-it.js" /* webpackChunkName: "component---src-pages-servicii-training-it-js" */),
  "component---src-pages-servicii-transformare-digitala-js": () => import("./../../../src/pages/servicii/transformare-digitala.js" /* webpackChunkName: "component---src-pages-servicii-transformare-digitala-js" */),
  "component---src-pages-solutii-consultanta-it-js": () => import("./../../../src/pages/solutii/consultanta-it.js" /* webpackChunkName: "component---src-pages-solutii-consultanta-it-js" */),
  "component---src-pages-solutii-dezvoltare-software-js": () => import("./../../../src/pages/solutii/dezvoltare-software.js" /* webpackChunkName: "component---src-pages-solutii-dezvoltare-software-js" */),
  "component---src-pages-solutii-echipe-dedicate-js": () => import("./../../../src/pages/solutii/echipe-dedicate.js" /* webpackChunkName: "component---src-pages-solutii-echipe-dedicate-js" */),
  "component---src-pages-studii-de-caz-aplicatie-plati-mobile-js": () => import("./../../../src/pages/studii-de-caz/aplicatie-plati-mobile.js" /* webpackChunkName: "component---src-pages-studii-de-caz-aplicatie-plati-mobile-js" */),
  "component---src-pages-studii-de-caz-companie-aeriana-js": () => import("./../../../src/pages/studii-de-caz/companie-aeriana.js" /* webpackChunkName: "component---src-pages-studii-de-caz-companie-aeriana-js" */),
  "component---src-pages-studii-de-caz-inchirieri-auto-js": () => import("./../../../src/pages/studii-de-caz/inchirieri-auto.js" /* webpackChunkName: "component---src-pages-studii-de-caz-inchirieri-auto-js" */),
  "component---src-pages-studii-de-caz-js": () => import("./../../../src/pages/studii-de-caz.js" /* webpackChunkName: "component---src-pages-studii-de-caz-js" */),
  "component---src-pages-studii-de-caz-sistem-management-transport-js": () => import("./../../../src/pages/studii-de-caz/sistem-management-transport.js" /* webpackChunkName: "component---src-pages-studii-de-caz-sistem-management-transport-js" */)
}

